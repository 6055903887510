<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div v-if="totalVideos >= 0" class="search-results">{{ totalVideos }} results found</div>
            </div>
            <div class="view-options d-flex">
              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                  <feather-icon :icon="option.icon" size="18" />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="filters.q" placeholder="Search Video" class="search-product" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card v-for="video in videos" :key="video.id" class="ecommerce-card" no-body>
        <div class="item-img text-center">
          <b-link :to="{ name: 'video', params: { id: video.id } }">
            <b-aspect aspect="16:9">
              <div class="video" fluid :style="`background-image: url(${video.coverUrl});`" />
            </b-aspect>
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">{{ video.viewCount }} views</div>
            <div>
              <h6 class="item-price">
                {{ video.contentDetails.duration | duration('humanize') }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link class="text-body" :to="{ name: 'video', params: { id: video.id } }">
              <!-- <feather-icon v-if="photo.if" icon="HeartIcon" class="mr-50 text-danger" /> -->
              {{ video.snippet.title }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link
                :href="'https://www.youtube.com/channel/' + video.snippet.channelId"
                target="_blank"
                class="ml-25"
              >
                {{ video.snippet.channelTitle }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ video.snippet.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">{{ video.contentDetails.duration | duration('humanize') }}</h4>
            </div>
          </div>
          <!-- <b-button variant="light" tag="a" class="btn-wishlist">
            <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': true }" />
            <span>Wishlist</span>
          </b-button> -->
          <b-button
            v-if="isAdmin"
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="{ name: 'edit-video', params: { videoId: video.id } }"
          >
            <!-- <feather-icon icon="CheckIcon" class="mr-50" /> -->
            <span>Manage Video</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-if="totalVideos >= 0"
            v-model="filters.page"
            :total-rows="totalVideos"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <youtube-left-filter-sidebar
        :embedded="embedded"
        :filters="filters"
        :filter-options-channels="filterOptionsChannels"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @gallery-close="$emit('gallery-close')"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  // BImg,
  BCardText,
  BButton,
  BPagination,
  BAspect,
  // BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import mixinUI from '@/store/ui/mixin'
import mixinServer from '@/store/server/mixin'
import mixinDebug from './mixinDebug'

import YoutubeLeftFilterSidebar from './ViewYouTubeLeftFilterSidebar.vue'

export default {
  name: 'ViewYouTube',
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    // BImg,
    BCardText,
    BButton,
    BPagination,
    BAspect,
    // BBadge,

    // SFC
    YoutubeLeftFilterSidebar,
  },
  mixins: [mixinUI, mixinServer, mixinDebug],
  props: {
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // sortBy: { text: 'Views', value: 'viewCount' },
      sortByOptions: [
        { text: 'Views', value: 'viewCount' },
        { text: 'Newest', value: 'publishedAt-desc' },
        { text: 'Oldest', value: 'publishedAt-asc' },
      ],
      // filters: {
      //   q: '',
      //   channels: 'all',
      //   page: 1,
      //   perPage: 6,
      // },
      // itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      // Pagination count <= required by pagination component
      totalVideos: -1,
      videos: [],
    }
  },
  computed: {
    filterOptionsChannels() {
      const channels = store.state.server.youtubeChannels.map(channel => channel.title)
      channels.unshift('all')
      return channels
    },
    filters() {
      return store.state.ui.filtersYouTube
    },
    sortBy: {
      get() {
        return store.state.ui.sortByYouTube
      },
      set(val) {
        store.state.ui.sortByYouTube = val
      },
    },
    itemView: {
      get() {
        return store.state.ui.itemViewYouTube
      },
      set(val) {
        store.state.ui.itemViewYouTube = val
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchVideos()
      },
      deep: true,
    },
    sortBy: {
      handler() {
        this.fetchVideos()
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('server/fetchAllVideos')
      .then(() => {
        next()
      })
      .catch(() => {
        this.log('fetchAllVideos error')
        next()
      })
  },
  beforeMount() {
    this.fetchVideos()
  },
  methods: {
    fetchVideos() {
      this.log('fetchVideos page=', this.filters.page)
      store
        .dispatch('server/fetchVideos', {
          q: this.filters.q,
          sortBy: this.sortBy.value,
          channels: this.filters.channels,
          page: this.filters.page,
          perPage: this.filters.perPage,
        })
        .then(response => {
          this.log(response)
          this.videos = response.videos
          this.totalVideos = response.total
        })
    },
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    return {
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  align-items: start;
}

.item-img {
  a {
    width: 100%;

    .video {
      background-color: #000;
      width: 100%;
      height: 100%;
      // height: 280px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
</style>
